if (window.boostSDAppConfig) {
  window.boostSDAppConfig.integration =  Object.assign({
    wishlists: 'plus'
  }, window.boostSDAppConfig.integration || {});
}

window.boost_grid_cnt = 0;


function insertAfter(newNode, existingNode) {
  existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
}
const updateProductItem = (componentRegistry) => {

    componentRegistry.useComponentPlugin('ProductItem', {

        name: 'Add SKU and specsheet to product card',
        enabled: true,
        apply: () => ({
            afterRender(element){
                try{
                    let productData = element.getParams().props.product;
                    let productItem = document.querySelector('[data-product-id="'+ productData.id +'"]');

                  let indexData =  element.getParentElm().getParentElm().getParams().props.children.props.children.map(e => {
                    return {'id': parseFloat(e.key.split('_')[0]), 'index': parseFloat(e.key.split('_')[1])}
                  })
                  let currentIndex = indexData.find(e => e.id === productData.id);

                  let circle_a = ["one_third","one_third","one_third","one_two","one_two"];
                  let circle_b = ["mob_one","mob_two","mob_three","mob_four","mob_five"];

                  window.boost_grid_cnt++;
                  console.log(window.boost_grid_cnt + ' boost_grid_cnt');
                  productItem.classList.add(circle_a[window.boost_grid_cnt - 1]);
                  productItem.classList.add(circle_b[window.boost_grid_cnt - 1]);
                  if(window.boost_grid_cnt == 5){
                    window.boost_grid_cnt = 0;
                  }
                  // if (typeof currentIndex !== 'undefined') {
                  //   let newIndex = currentIndex.index;
                  //   if (currentIndex.index > 4 && currentIndex.index < 10) {
                  //     newIndex = currentIndex.index - 5;
                  //   } else
                  //   if (currentIndex.index > 9 && currentIndex.index < 15) {
                  //     newIndex = currentIndex.index - 10;
                  //   } else
                  //   if (currentIndex.index > 14 && currentIndex.index < 20) {
                  //     newIndex = currentIndex.index - 15;
                  //   } else
                  //   if (currentIndex.index > 19 && currentIndex.index < 25) {
                  //     newIndex = currentIndex.index - 20;
                  //   } else
                  //   if (currentIndex.index > 24 && currentIndex.index < 30) {
                  //     newIndex = currentIndex.index - 25;
                  //   }
                  //   if (circle_a[newIndex] !== 'undefined') {
                  //     productItem.classList.add(circle_a[newIndex]);
                  //     productItem.classList.add(circle_b[newIndex]);
                  //   }
                  // }
                  var size_html = '<div class="size_swatch"><ul class="flexbox align-center">';
                  for (var m = 0; m < productData.options_with_values.length; m++) {
                    if(productData.options_with_values[m].name == 'size'){
                      var all_values = [];
                      for (var i = 0; i < productData.variants.length; i++) {
                          var value = productData.variants[i].merged_options[m].replace('size:',''),
                            var_available = (productData.variants[i].available) ? 'available' : 'soldout';
                          if(all_values.indexOf(value) == -1){
                             all_values.push(value); 
                            size_html += '<li class="'+ var_available +'">'+ value +'</li>';
                          }
                      }
                    }
                  }
                  size_html += '</ul></div>';
                  if($(productItem).find('.size_swatch').length == 0){
                    $(productItem).find('.boost-sd__product-title').after(size_html); 
                  }
                } catch (e) {
                }
            }
        }),

    });
}

window.__BoostCustomization__ = (window.__BoostCustomization__ ?? []).concat([
  (componentRegistry) => {
    const hideLimitParam = {
      name: 'Hide limit on URL',
      apply(){
        return{
          props(props){
            props.showLimitOnUrl = false;
            return props;
          }
        }
      }
    };

    componentRegistry.useComponentPlugin(
      "FilterStateProvider",
      hideLimitParam
    );
  },
]);


window.__BoostCustomization__ = (window.__BoostCustomization__ ?? []).concat([updateProductItem]);

if (window.boostSDAppConfig) {
  window.boostSDAppConfig.integration =  Object.assign({
    wishlists: 'plus'
  }, window.boostSDAppConfig.integration || {});
}